import React, { useState } from "react"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images

import { Button, Input, Modal, Tag } from "antd"
import Evertest_001 from "../images/images/bracelets_evertest/Evertest_001.jpg"

import Evertest_002 from "../images/images/bracelets_evertest/Evertest_002.jpg"
import Evertest_003 from "../images/images/bracelets_evertest/Evertest_003.jpg"
import Evertest_004 from "../images/images/bracelets_evertest/Evertest_004.jpg"
import Evertest_005 from "../images/images/bracelets_evertest/Evertest_005.jpg"
import Evertest_006 from "../images/images/bracelets_evertest/Evertest_006.jpg"
import Evertest_007 from "../images/images/bracelets_evertest/Evertest_007.jpg"
import Evertest_008 from "../images/images/bracelets_evertest/Evertest_008.jpg"
import Evertest_009 from "../images/images/bracelets_evertest/Evertest_009.jpg"

const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Montres Maserati"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Bracelets Everestbands</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>
          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Bracelets Everestbands</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro">
                <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={Evertest_009} alt="Bracelets Everestbands pour les montre Rolex"/>
                  <div>
                  <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>
                    Everest est une entreprise américaine qui produit des bracelets et des fournitures qui sont conçus spécifiquement pour les montre Rolex.
                  </p>
                  <p  className="p-2 p-font" style={{paddingTop: 0, marginTop: 0}}>
                    Les bracelets sont produits en Suisse et sont de haute qualité afin de pouvoir satisfaire le client mais surtout pouvoir égaler la marque à la couronne.
                  </p>
                </div>
              </div>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_001} data-attribute="SRL"><img className="img-gallery" src={Evertest_001}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_002} data-attribute="SRL"><img className="img-gallery" src={Evertest_002}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_003} data-attribute="SRL"><img className="img-gallery" src={Evertest_003}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_004} data-attribute="SRL"><img className="img-gallery" src={Evertest_004}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_005} data-attribute="SRL"><img className="img-gallery" src={Evertest_005}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_006} data-attribute="SRL"><img className="img-gallery" src={Evertest_006}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_007} data-attribute="SRL"><img className="img-gallery" src={Evertest_007}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Evertest_008} data-attribute="SRL"><img className="img-gallery" src={Evertest_008}
                                                                       alt="Yvan Monnet"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>

              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries desktop-display-none" onClick={ () => showModalContactForm('Bracelets Everestbands') }>Nous contacter</Button></div>

              <div className="mobile-display" style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: '10%', paddingRight: '10%', marginTop: '50px'}}>
                <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Un grand choix de bracelets pour tous les styles.</span>
                <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Retrouvez la dernière collection dans nos boutiques.</span>
                <span className="mobile-display" style={{fontSize: '1.6em', textAlign: 'center', padding: '12px'}}>Pour toutes questions, n’hésitez pas à nous contacter.</span>

              </div>

              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries btn-tnm mobile-display" onClick={ () => showModalContactForm('Bracelets') }>Toutes marques sur demandes</Button></div>

            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm page={dataContact} />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
